import BaseController from '@/service/BaseService'
import WebsiteRequest from '@/request/Business/WebsiteRequest'
import { errorTip } from '@/utils/Tip'

const base = new BaseController()

export default {
  data () {
    return {
      showDialog: false, // 是否显示弹窗
      showLoading: false,
      scene: 0, // 场景: 0表示弹框为添加，1表示弹框为修改
      active: 1,
      contact: [],
      tableForKey: true,
      // 表单信息
      form: {
        TitleCn: '',
        Address: '',
        TemplateId: 9,
        Color: '#409EFF',
        PriceType: 1,
        BannerArray: [],
        SchoolIdArray: [],
        CityArray: [],
        TeacherId: '',
        SubmissionDeadline: '',
        RegistrationDeadline: '',
        ConferenceDate: '',
        Status: 1,
        // 网站介绍
        About: '',
        // 会议委员会
        ConferenceCommittee: '',
        // 项目委员会
        ProgramCommittee: '',
        // 技术委员会
        InternationalTechnicalCommittee: '',
        // 调用文件
        CallPapers: ''
      },
      // 表单验证规则
      rules: {
        Color: [
          {
            required: true,
            message: '请选择主题颜色'
          }],
        SchoolIdArray: [
          {
            required: true,
            message: '请选择学校'
          }],
        CityArray: [
          {
            required: true,
            message: '请选择地址'
          }],
        Address: [{
          required: true,
          message: '请输入会议地址'
        }],
        TeacherId: [{
          required: true,
          message: '请选择老师'
        }],
        SubmissionDeadline: [{
          required: true,
          message: '请输入提交截止日期'
        }],
        RegistrationDeadline: [{
          required: true,
          message: '请输入注册截止日期'
        }],
        ConferenceDate: [{
          required: true,
          message: '请输入会议时间'
        }],
        About: [{
          required: true,
          message: '请输入简介'
        }],
        ConferenceCommittee: [{
          required: true,
          message: '请输入会议委员会'
        }],
        ProgramCommittee: [{
          required: true,
          message: '请输入项目委员会'
        }],
        InternationalTechnicalCommittee: [{
          required: true,
          message: '请输入技术委员会'
        }],
        CallPapers: [{
          required: true,
          message: '请输入征稿主题'
        }],
        Status: [{
          required: true,
          message: '请输入状态'
        }]
      },

      // 分页信息[前端控制单页数量]
      pageData: {
        Page: 1,
        Limit: 10,
        Total: 0,
        List: [],
        Select: {
          PriceType: {},
          Status: {},
          Template: {},
          Teacher: {},
          School: {}
        }
      },

      // 表单筛选
      searchForm: {
        PriceType: null,
        Domain: null,
        Status: null,
        TemplateId: null,
        TeacherId: null,
        Identifying: null,
        Expired: null,
        CreateTimeArray: []
      }
    }
  },
  // 初始化函数
  created: async function () {
    await base.init(this, new WebsiteRequest()).getList(this.pageData, this.searchForm)
  },
  methods: {
    changCity: function (val) {
      this.form.CityArray = val
    },
    changeTemplate: function (va) {
      // console.log(1111)
      if (va !== 11) {
        this.form.Color = ''
      }
    },
    // 输入日期改变
    changeDate: function (va) {
      const startDate = (va === 1)
        ? new Date(this.form.SubmissionDeadline)
        : new Date(this.form.SubmissionDeadline)
      // 检查日期是否有效
      if (isNaN(startDate)) errorTip(this, '请输入正确的日期格式')
      startDate.setDate(startDate.getDate() + 7)
      if (va === 1) {
        this.form.RegistrationDeadline = startDate.toISOString().substring(0, 10)
        startDate.setDate(startDate.getDate() + 7)
        this.form.ConferenceDate = startDate.toISOString().substring(0, 10)
      } else {
        this.form.ConferenceDate = startDate.toISOString().substring(0, 10)
      }
    },
    previousStepClick: function () {
      this.active -= 1
    },
    nextStepClick: function () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.active += 1
        }
      })
    },
    // 导入事件
    importClick: async function ({ file }) {
      await base.importClick(file)
    },
    // 导出事件
    exportClick: async function () {
      try {
        this.showLoading = true
        await base.exportClick()
      } finally {
        this.showLoading = false
      }
    },
    // 提交事件
    submitClick: async function () {
      await base.submitClick()
      this.active = 1
    },
    // 取消事件
    cancelDialog: async function () {
      this.active = 1
      await base.cancelDialog()
    },
    // 删除事件
    deleteClick: async function (item) {
      await base.deleteClick(item)
    },
    // 搜索事件
    searchClick: async function () {
      try {
        this.showLoading = true
        this.pageData.Page = 1
        await base.getList()
      } finally {
        this.showLoading = false
      }
    },
    // 重置事件
    resetClick: async function () {
      await base.resetClick()
    },
    // 添加事件
    addClick: function () {
      base.addClick()
    },
    handleSizeChange: async function (val) {
      try {
        this.showLoading = true
        this.pageData.Limit = val
        this.pageData.Page = 1
        await base.getList()
      } finally {
        this.showLoading = false
      }
    },
    handleCurrentChange: async function (val) {
      try {
        this.showLoading = true
        await base.pagingClick(val)
      } finally {
        this.showLoading = false
      }
    },
    // 编辑事件
    editClick: async function (item) {
      await base.MoreClick(item)
    },
    // 子组件修改父组件data
    changDataValue: function (field, value) {
      this.form[field] = value
    }
  }
}
