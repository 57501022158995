<template>
  <div class="manage">

    <!--    搜索区域开始   -->
    <el-collapse>
      <el-collapse-item title="搜索" name="1">
        <el-form :model="searchForm"
                 :inline="true"
                 label-width="80px">
          <el-form-item label="价格类型">
            <el-select v-model="searchForm.PriceType" placeholder="请选择价格类型">
              <el-option
                v-for="(item, index) in pageData.Select.PriceType"
                :key="index"
                :label="item"
                :value="index*1">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="老师">
            <el-select v-model="searchForm.teacherId" placeholder="请选择老师">
              <el-option
                v-for="(item, index) in pageData.Select.Teacher"
                :key="index"
                :label="item"
                :value="index*1">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="网站域名">
            <el-input placeholder="请输入网站域名" v-model="searchForm.Domain"/>
          </el-form-item>
          <el-form-item label="会议简称">
            <el-input placeholder="请输入会议简称" v-model="searchForm.Identifying"/>
          </el-form-item>
          <el-form-item label="站点状态">
            <el-select v-model="searchForm.Status" placeholder="请选择站点状态">
              <el-option
                v-for="(item, index) in pageData.Select.Status"
                :key="index"
                :label="item"
                :value="index*1">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="模板">
            <el-select v-model="searchForm.TemplateId" placeholder="请选择模板">
              <el-option
                v-for="(item, index) in pageData.Select.Template"
                :key="index"
                :label="item"
                :value="index*1">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="会议过期">
            <el-select v-model="searchForm.Expired" placeholder="请选择过期状态">
              <el-option label="未过期" value="1"/>
              <el-option label="过期" value="2"/>
            </el-select>
          </el-form-item>
        </el-form>
      </el-collapse-item>
    </el-collapse>
    <!--    搜索区域结束   -->

    <!--    分割线-->
    <el-divider></el-divider>

    <!-- 弹窗区域开始 -->
    <el-dialog
      :title="`${form.TitleCn} < ${form.Identifying} >`"
      :visible.sync="showDialog"
      width="90%"
      :close-on-click-modal="false"
      :center="true"
      :before-close="cancelDialog">
      <el-steps :active="active" finish-status="success" :key="new Date().getTime()">
        <el-step title="基础配置"></el-step>
        <el-step title="图片组"></el-step>
        <el-step title="文本组"></el-step>
      </el-steps>

      <span class="footer">
          <el-button @click="cancelDialog" v-if="active === 1">取 消</el-button>
          <el-button @click="previousStepClick" v-if="active !== 1">上一步</el-button>
          <el-button @click="nextStepClick" v-if="active !== 3">下一步</el-button>
          <el-button type="primary" @click="submitClick" v-if="active === 3">确 定</el-button>
      </span>
      <!-- 用户的表单信息 -->
      <el-form
        ref="form"
        :model="form"
        label-width="auto"
        :rules="rules">
        <div v-if="active === 1">
          <el-form-item label="价格类型" prop="PriceType">
            <el-select v-model="form.PriceType" placeholder="请选择价格类型" style="width: 100%;">
              <el-option v-for="(item, index) in pageData.Select.PriceType"
                         :key="index"
                         :label="item"
                         :value="index*1"/>
            </el-select>
          </el-form-item>

          <el-form-item label="会议地址" prop="Address">
            <el-input placeholder="请输入会议地址"
                      v-model="form.Address"/>
          </el-form-item>
          <el-form-item label="老师" prop="TeacherId">
            <el-select v-model="form.TeacherId" placeholder="请选择价格类型" style="width: 100%;">
              <el-option v-for="(item, index) in pageData.Select.Teacher"
                         :key="index"
                         :label="item"
                         :value="index*1"/>
            </el-select>
          </el-form-item>

          <el-form-item label="截稿日期" prop="SubmissionDeadline">
            <el-input placeholder="请输入截稿日期"
                      v-model="form.SubmissionDeadline"
                      @change="changeDate(1)"/>
          </el-form-item>

          <el-form-item label="注册日期" prop="RegistrationDeadline">
            <el-input placeholder="请输入注册日期"
                      v-model="form.RegistrationDeadline"
                      @change="changeDate(2)"/>
          </el-form-item>

          <el-form-item label="会议时间" prop="ConferenceDate">
            <el-input placeholder="请输入会议时间" v-model="form.ConferenceDate"/>
          </el-form-item>

          <el-form-item label="状态" prop="Status">
            <el-select v-model="form.Status" placeholder="请选择状态" style="width: 100%;">
              <el-option v-for="(item, index) in pageData.Select.Status"
                         :key="index"
                         :label="item"
                         :value="index*1"/>
            </el-select>
          </el-form-item>

        </div>

        <div v-if="active === 2">
          <el-form-item label="模板" prop="TemplateId">
            <el-select v-model="form.TemplateId" placeholder="请选择模板" style="width: 100%;" @change="changeTemplate">
              <el-option v-for="(item, index) in pageData.Select.Template"
                         :key="index"
                         :label="item"
                         :value="index*1"/>
            </el-select>
          </el-form-item>

          <el-form-item label="学校" prop="SchoolIdArray">
            <el-select v-model="form.SchoolIdArray"
                       multiple
                       :multiple-limit="4"
                       placeholder="请选择"
                       style="width: 100%">
              <el-option v-for="(item, index) in pageData.Select.School"
                         :key="index"
                         :label="item"
                         :value="index*1"/>
            </el-select>
          </el-form-item>

          <el-form-item v-if="form.TemplateId === 11" label="主题颜色" prop="Color">
            <el-color-picker v-model="form.Color" style="width: 50px;"></el-color-picker>
          </el-form-item>

          <el-form-item label="轮播图" prop="BannerArray">
            <McUpload :limit="10"
                      field="bannerArray"
                      :files="form.BannerArray" type="image"
                      @event="changDataValue"/>
          </el-form-item>

          <el-form-item label="地图" prop="CityArray">
            <McMapScreenshot
              field="CityArray"
              :city="form.CityArray"
              @event="changDataValue"
              :key="new Date().getTime()"/>
          </el-form-item>
        </div>

        <div v-if="active === 3">
          <el-form-item label="网站介绍" prop="About">
            <el-input
              type="textarea"
              :autosize="{ minRows: 5}"
              placeholder="请输入网站介绍"
              v-model="form.About"/>
          </el-form-item>

          <el-form-item label="会议委员会" prop="ConferenceCommittee">
            <el-input
              type="textarea"
              :autosize="{ minRows: 5}"
              placeholder="请输入会议委员会"
              v-model="form.ConferenceCommittee"/>
          </el-form-item>

          <el-form-item label="项目委员会" prop="ProgramCommittee">
            <el-input
              type="textarea"
              :autosize="{ minRows: 5}"
              placeholder="请输入项目委员会"
              v-model="form.ProgramCommittee"/>
          </el-form-item>

          <el-form-item label="技术委员会" prop="InternationalTechnicalCommittee">
            <el-input
              type="textarea"
              :autosize="{ minRows: 5}"
              placeholder="请输入技术委员会"
              v-model="form.InternationalTechnicalCommittee"/>
          </el-form-item>

          <el-form-item label="征稿主题" prop="CallPapers">
            <el-input
              type="textarea"
              :autosize="{ minRows: 5}"
              placeholder="请输入技术委员会"
              v-model="form.CallPapers"/>
          </el-form-item>
        </div>

      </el-form>
    </el-dialog>
    <!-- 弹窗区域结束 -->

    <!-- 表头按钮区域开始 -->
    <div class="manage-header">
      <div>
<!--        <el-button size="mini" class="el-icon-plus" type="primary" @click="addClick" plain>新增</el-button>-->
        <el-button size="mini" class="el-icon-search" type="success" @click="searchClick" plain>搜索</el-button>
        <el-button size="mini" class="el-icon-refresh-right" type="warning" @click="resetClick" plain>重置</el-button>
<!--        <el-button size="mini" class="el-icon-download" type="info" @click="exportClick" plain>导出</el-button>-->
      </div>
    </div>
    <!-- 表头按钮区域结束 -->

    <!-- 表格 -->
    <el-table
      :data="pageData.List"
      style="width: 100%;margin-bottom: 20px;background-color: #ededed"
      row-key="id"
      border
      v-loading="showLoading">
      <el-table-column prop="Id" label="ID"/>
      <el-table-column prop="Identifying" label="网站标识"/>
      <el-table-column prop="PriceTypeText" label="价格类型"/>
      <el-table-column prop="TemplateText" label="模板"/>
      <el-table-column prop="TitleCn" label="网站标题"/>
      <el-table-column prop="Domain" label="网站域名">
        <template v-slot="{row}">
          <a :href="`https://${row.Domain}/`" target="_blank">{{ row.Domain }}</a>
        </template>
      </el-table-column>
      <el-table-column prop="SubmissionDeadline" label="截稿日期"/>
      <el-table-column prop="TeacherText" label="归属"/>
      <el-table-column prop="Visits" label="浏览量"/>
      <el-table-column prop="StatusText" label="状态"/>
      <el-table-column prop="CreateTime" label="创建时间"/>
      <el-table-column prop="UpdateTime" label="更新时间"/>
      <el-table-column label="操作">
        <template v-slot="{row}">
          <el-button
            size="mini"
            @click="editClick(row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageData.Page"
        :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
        :page-size="pageData.Limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageData.Total">
      </el-pagination>
    </div>
  </div>
</template>

<script>

import '@/assets/static/css/base.css'
import service from '@/service/Business/WebsiteService'

export default service
</script>

<style>
.footer {
  width: 100%;
  display: flex;
  margin: 1vw 0 1vw 0;
  justify-content: space-between;
}
</style>
